import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import wfrleeLogo from "../../../../../assets/images/logo.svg";

export const ModalHeader = () => {
  const [t] = useTranslation("common");

  return (
    <div className={styles.header}>
      <img src={wfrleeLogo} alt="Wfrlee" className={styles.logo} />
      <div className={styles.titleAndSubtitleContainer}>
        <p className={styles.popupTitle}>{t("Verify Your Phone Number")}</p>
        <p className={styles.popupSubtitle}>
          {t("We will send you the OTP code to below number")}
        </p>
      </div>
    </div>
  );
};
