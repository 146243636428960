import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import MapMarkerHoveredCard from "../MapMarkerHoveredCard";

const mapContainerStyle = {
  height: "auto",
  width: "100%",
};

const libraries = ["geometry", "drawing", "places"];

const MapWithPlaces = ({ places, searchData, center, zoom, activeMarker }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GoogleAPIKEY,
    libraries,
  });

  const mapRef = React.useRef();
  const [hoveredIndex, setHoveredIndex] = useState(null); // To track hovered marker
  const [infoWindows, setInfoWindows] = useState(
    places.map(() => ({ isOpen: false }))
  );

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (mapRef.current && places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        const lat = parseFloat(place.location.coordinates[1]);
        const lng = parseFloat(place.location.coordinates[0]);
        bounds.extend({ lat, lng });
      });

      mapRef.current.fitBounds(bounds);

      // Optional: Limit maximum zoom level
      const listener = window.google.maps.event.addListenerOnce(
        mapRef.current,
        "bounds_changed",
        () => {
          const currentZoom = mapRef.current.getZoom();
          if (currentZoom > 15) {
            mapRef.current.setZoom(15);
          }
        }
      );
    } else if (mapRef.current) {
      // Fallback to initial center/zoom if no places
      mapRef.current.setCenter(center);
      mapRef.current.setZoom(zoom);
    }
  }, [places, center, zoom]);

  const handleMarkerClick = useCallback(
    (index) => {
      const updatedInfoWindows = infoWindows.map((iw, i) => ({
        isOpen: i === index,
      }));

      setInfoWindows(updatedInfoWindows);

      const selectedPlace = places[index];
      const checkInDate = searchData.checkInTime.split("/").join("%2F");
      const checkOutDate = searchData.checkOutTime
        ? searchData.checkOutTime.split("/").join("%2F")
        : "null";

      setHoveredIndex(index);
      if (hoveredIndex === index) {
        window.open(
          `https://wfrlee.com/hotel-details/${selectedPlace.slug}?search_type=${searchData.searchType}&book_for=3&city=${searchData.place}&check_in_date=${checkInDate}&check_out_date=${checkOutDate}&adults=1&children=0&rooms=1`,
          "_self"
        );
      }
    },
    [infoWindows, hoveredIndex, places, searchData]
  );

  const onMapMarkerHoveredCardClick = useCallback(
    (index) => {
      const updatedInfoWindows = infoWindows.map((iw, i) => ({
        isOpen: i === index,
      }));

      setInfoWindows(updatedInfoWindows);

      const selectedPlace = places[index];
      const checkInDate = searchData.checkInTime.split("/").join("%2F");
      const checkOutDate = searchData.checkOutTime
        ? searchData.checkOutTime.split("/").join("%2F")
        : "null";

      if (hoveredIndex === index) {
        window.open(
          `https://wfrlee.com/hotel-details/${selectedPlace.slug}?search_type=${searchData.searchType}&book_for=3&city=${searchData.place}&check_in_date=${checkInDate}&check_out_date=${checkOutDate}&adults=1&children=0&rooms=1`,
          "_self"
        );
      }
    },
    [infoWindows, places, searchData, hoveredIndex]
  );

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={zoom}
      onClick={() => onMapMarkerHoveredCardClick(null)}
      onLoad={onMapLoad}
    >
      {places.map((place, index) => {
        const lat = parseFloat(place.location.coordinates[1]);
        const lng = parseFloat(place.location.coordinates[0]);

        return (
          <Marker
            key={place._id}
            position={{ lat, lng }}
            title="Click to zoom"
            onClick={() => handleMarkerClick(index)}
            onMouseOver={() => setHoveredIndex(index)}
            onMouseOut={() => setHoveredIndex(null)}
            animation={activeMarker === index ? "bounce" : null}
            icon={{
              url:
                activeMarker === index
                  ? "/img/map-marker-active.png"
                  : "/img/map-marker.png",
              scaledSize:
                activeMarker === index
                  ? new window.google.maps.Size(32, 52)
                  : new window.google.maps.Size(24, 38),
            }}
          >
            {(hoveredIndex === index || infoWindows[index].isOpen) && (
              <InfoWindow>
                <MapMarkerHoveredCard
                  place={place}
                  onClick={() => onMapMarkerHoveredCardClick(index)}
                />
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </GoogleMap>
  );
};

export default MapWithPlaces;
