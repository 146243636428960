import * as Yup from "yup";

export const createValidationSchema = (t) =>
  Yup.object().shape({
    phoneno: Yup.string()
      .required("PleaseEnterValidPhoneNumber")
      .min(10, "loginAndRegisterPage.validationMsg.digitnumberallowonly10"),
    country_code: Yup.string().required("Required"),
    otpcode: Yup.string()
      .length(6, "Code is not completed")
      .required("Code is required"),
  });
