import React from "react";
import { Button } from "@mui/material";
import { Form as BootstrapForm } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { handleNumberInput } from "../../../../../utils/helpers";
import moment from "moment";

export const OTPInputSection = ({
  requestOTPButtonDisabled,
  onRequestCode,
  countdown,
  codeRequested,
}) => {
  const [t] = useTranslation("common");

  return (
    <div>
      <div className={styles.codeInputAndButtonContainer}>
        <BootstrapForm.Group controlId="formBasicCode">
          <Field
            name="otpcode"
            as={BootstrapForm.Control}
            type="text"
            placeholder={t("Enter the 6-digit code")}
            className={styles.codeInputField}
            minLength={6}
            maxLength={6}
            onKeyDown={handleNumberInput}
          />
        </BootstrapForm.Group>
        <Button
          className={styles.sendCodeButton}
          onClick={onRequestCode}
          disabled={requestOTPButtonDisabled}
          type={"button"}
        >
          {countdown > 0
            ? moment.utc(countdown * 1000).format("mm:ss")
            : codeRequested
              ? t("Resend Now")
              : t("Send Now")}
        </Button>
      </div>
      <ErrorMessage
        name="otpcode"
        component="div"
        children={(errorMessage) => (
          <p className={"text-muted errorformmessage"}>{t(errorMessage)}</p>
        )}
      />
    </div>
  );
};
