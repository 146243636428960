export const EXCLUDED_COUNTRIES = [
  "cu",
  "ru",
  "kp",
  "ua",
  "ir",
  "sd",
  "ss",
  "sy",
];
export const INITIAL_VALUES = {
  phoneno: "",
  country_code: "+966",
  otpcode: "",
};
export const NUMBER_ONLY_ALLOWED_KEYS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Home",
  "End",
];
export const BACKEND_URL_ENDPOINT = process.env.REACT_APP_BACKEND_API_ENDPOINT;
