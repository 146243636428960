import React from "react";
import { Field, ErrorMessage } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import styles from "./styles.module.css";
import { EXCLUDED_COUNTRIES } from "./constants";
import { useTranslation } from "react-i18next";
import { handleNumberInput } from "../../../../../utils/helpers";

export const PhoneNumberInput = () => {
  const [t] = useTranslation("common");

  return (
    <BootstrapForm.Group controlId="formBasicPhone">
      <div className={styles.countryCodePhoneInputContainer}>
        <Field name="country_code">
          {({ field, form }) => (
            <PhoneInput
              {...field}
              enableSearch
              excludeCountries={EXCLUDED_COUNTRIES}
              inputProps={{ readOnly: true }}
              className={styles.phoneInputField}
              onChange={(value) => form.setFieldValue("country_code", value)}
            />
          )}
        </Field>
        <Field
          name="phoneno"
          as={BootstrapForm.Control}
          type="tel"
          placeholder={t("loginAndRegisterPage.PhoneNumber")}
          maxLength={10}
          minLength={10}
          onKeyDown={handleNumberInput}
        />
      </div>
      <ErrorMessage
        name="phoneno"
        component="div"
        children={(errorMessage) => (
          <p className={"text-muted errorformmessage"}>{t(errorMessage)}</p>
        )}
      />
    </BootstrapForm.Group>
  );
};
