import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  ResetPasswordStart,
  ProfileDataUpdateRequest,
  ProfileImageUpdateRequest,
  stateClearAfterTask,
  UserProfileDetailsRequest,
  ProfileImageUpdateMessageClear,
  UserProfileDetailsUpdateThroughProfile,
} from "./../../redux/user/user.actions";
import { selectLanguage } from "./../../redux/language/language.actions";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import {
  selectCurrentUser,
  selectUserProfileMessage,
  selectUserResetPassword,
  selectImageUploadMessage,
} from "./../../redux/user/user.selectors";
import Countries from "./../../assets/country-data/countrydata.json";
import DefaultImage from "./../../assets/images/istockphoto-1341046662-612x612.jpg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import { setUserLocal } from "../../config/common.api";
import store from "../../../src/redux/store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { saveGuestUserCheckoutDataRequest } from "../../redux/hotels/hotel.actions";
import { UserLogoutStart } from "../../redux/user/user.actions";
import axios from "axios";
import PhoneVerificationModal from "../../components/common/dialogs/ReconfirmUserData/PhoneVerification/PhoneVerificationModal";

var MySwal = withReactContent(Swal);

const MyprofilePage = ({
  ResetPasswordStart,
  languageToShow,
  selectCurrentUser,
  ProfileDataUpdateRequest,
  ProfileImageUpdateRequest,
  selectUserProfileMessage,
  stateClearAfterTask,
  UserProfileDetailsRequest,
  resetPasswordData,
  ProfileImageUpdateMessageClear,
  imageUploadMsg,
  UserLogoutStart,
  updateUser,
}) => {
  const [t] = useTranslation("common");
  const [show, setShow] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const inputRef = useRef();
  const [imageFile, setImageFile] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] =
    useState(false);
  function handleShowChanePasswrd() {
    setShow(!show);
  }

  const [eyes, setEyes] = useState({
    oldpassword: false,
    password: false,
    confirmpassword: false,
  });
  const [userData, setUserData] = useState({
    oldpassword: "",
    password: "",
    confirmPassword: "",
  });
  const [userDataError, setuserDataError] = useState({
    oldpasswordErr: "",
    passwordErr: "",
    confirmPasswordErr: "",
  });
  const [userProfileData, setUserProfileData] = useState({
    first_name: selectCurrentUser.first_name,
    last_name: selectCurrentUser.last_name,
    contact_no: selectCurrentUser.contact_no,
    country_code: selectCurrentUser.country_code,
    profile_image: selectCurrentUser.profile_image,
    email: selectCurrentUser.email,
  });
  const [profieDataError, setProfieDataError] = useState({
    first_name_error: "",
    last_name_error: "",
    contact_no_error: "",
    country_code_error: "",
  });
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const [ImageUploadError, setImageUploadError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    if (e.target.name == "oldpassword") {
      setuserDataError({
        ...userDataError,
        oldpasswordErr: "",
      });
    } else if (e.target.name == "password") {
      setuserDataError({
        ...userDataError,
        passwordErr: "",
      });
    } else if (e.target.name == "confirmPassword") {
      setuserDataError({
        ...userDataError,
        confirmPasswordErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    console.log("Entered Here OK");

    var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
    let token = localStorage.getItem("token");
    const headers = {
      "Access-Control-Allow-Origin": "*",
      mobile: true,
      "Accept-Language": `${store.getState().language.language}`,
      Authorization:
        store.getState().user.currentUser != null
          ? "Bearer " + store.getState().user.currentUser.token + ""
          : "",
    };

    const deleteAccount = await fetch(`${backendUrlEndPoint}/user/delete`, {
      method: "DELETE",
      headers: headers,
    });
    const apiResponse = await deleteAccount.json();

    if (apiResponse?.success == true) {
      const data = null;
      setUserLocal("");
      localStorage.clear();
      UserLogoutStart();
      saveGuestUserCheckoutDataRequest(data);
      stateClearAfterTask();

      navigate("/");
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: apiResponse.message,
      });
    }
  };

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setUserProfileData({
        ...userProfileData,
        country_code: "",
      });
    } else {
      setUserProfileData({
        ...userProfileData,
        country_code: formattedValue,
      });
      setProfieDataError({
        ...profieDataError,
        country_code_error: "",
      });
    }
  };

  const handleResetPasswordSubmit = (e) => {
    e.preventDefault();

    if (userData.oldpassword == "") {
      setuserDataError({
        ...userDataError,
        oldpasswordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.oldPasswordErrMsg"
        ),
      });
      return;
    } else if (userData.oldpassword.length < 8) {
      setuserDataError({
        ...userDataError,
        oldpasswordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.passwordLengthErrMsg"
        ),
      });
      return;
    } else if (userData.password == "") {
      setuserDataError({
        ...userDataError,
        passwordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.passwordErrMsg"
        ),
      });
      return;
    } else if (userData.password.length < 8) {
      setuserDataError({
        ...userDataError,
        passwordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.passwordLengthErrMsg"
        ),
      });
      return;
    } else if (userData.confirmPassword == "") {
      setuserDataError({
        ...userDataError,
        confirmPasswordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.confirmPasswordErrMsg"
        ),
      });
      return;
    } else if (userData.confirmPassword != userData.password) {
      setuserDataError({
        ...userDataError,
        confirmPasswordErr: t(
          "MyAccount.MyProfilePage.ChangePasswordPage.form.validationMsg.passwordMatchedErrMsg"
        ),
      });
      return;
    } else {
      var data = {
        current_password: userData.oldpassword,
        new_password: userData.password,
        confirm_password: userData.confirmPassword,
      };
      ResetPasswordStart(data);
      setUserData({
        oldpassword: "",
        password: "",
        confirmPassword: "",
      });
    }
  };

  const handleKeyUp = (e) => {
    const englishReg = /^[a-zA-Z\s\-]*$/;
    if (e.target.name === "first_name") {
      const trimmedValue = e.target.value.trim();

      if (!englishReg.test(trimmedValue)) {
        console.log("english not entered");
        setuserDataError({
          ...userDataError,
          first_name_error: "English only, Please",
        });
      } else {
        console.log("english  entered");
        setuserDataError({
          ...userDataError,
          first_name_error: "",
        });
      }

      setUserData({
        ...userData,
        [e.target.name]: trimmedValue,
      });
    }

    if (e.target.name === "last_name") {
      const trimmedValue = e.target.value.trim();
      if (!englishReg.test(trimmedValue)) {
        setuserDataError({
          ...userDataError,
          last_name_error: "English only, Please",
        });
      } else {
        setuserDataError({
          ...userDataError,
          last_name_error: "",
        });
      }
      setUserData({
        ...userData,
        [e.target.name]: trimmedValue,
      });
    }
  };

  const handleChaneProfiledata = (e) => {
    const englishReg = /^[a-zA-Z\s\-]*$/;
    if (e.target.name === "first_name" && !englishReg.test(e.target.value)) {
      setProfieDataError({
        ...profieDataError,
        first_name_error: "English only",
      });
    } else if (e.target.name == "first_name") {
      setProfieDataError({
        ...profieDataError,
        first_name_error: "",
      });
    } else if (
      e.target.name === "last_name" &&
      !englishReg.test(e.target.value)
    ) {
      setProfieDataError({
        ...profieDataError,
        last_name_error: "English only",
      });
    } else if (e.target.name == "last_name") {
      setProfieDataError({
        ...profieDataError,
        last_name_error: "",
      });
    } else {
      setProfieDataError({
        ...profieDataError,
        contact_no_error: "",
      });
    }
    setUserProfileData({
      ...userProfileData,
      [e.target.name]: e.target.value,
    });
  };

  const submitProfileHandler = () => {
    setButtonSubmit(true);
    if (imageFile != "") {
      const formData = new FormData();
      formData.append("profile-image", userProfileData.profile_image);
      ProfileImageUpdateRequest(formData);
      setImageFile("");
    } else {
      let datachecking = userProfileData;
      delete datachecking.profile_image;
      ProfileDataUpdateRequest(datachecking);
    }
  };

  const handleProfileSubmit = (event) => {
    const englishReg = /^[a-zA-Z\s\-]*$/;
    event.preventDefault();
    setImageUploadError("");
    if (userProfileData.first_name == "") {
      setProfieDataError({
        ...profieDataError,
        first_name_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.nameErrMsg"
        ),
      });
      return;
    } else if (!englishReg.test(userProfileData.first_name)) {
      setProfieDataError({
        ...profieDataError,
        first_name_error: "English only",
      });
      return;
    } else if (!englishReg.test(userProfileData.last_name)) {
      setProfieDataError({
        ...profieDataError,
        last_name_error: "English only",
      });
      return;
    } else if (userProfileData.last_name == "") {
      setProfieDataError({
        ...profieDataError,
        last_name_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.lastnameErrMsg"
        ),
      });
      return;
    } else if (userProfileData.contact_no == "") {
      setProfieDataError({
        ...profieDataError,
        contact_no_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.mobileErrMsg"
        ),
      });
      return;
    } else if (userProfileData.contact_no.length < 10) {
      setProfieDataError({
        ...profieDataError,
        contact_no_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.mobileMinErrMsg"
        ),
      });
      return;
    } else if (isNaN(userProfileData.contact_no)) {
      setProfieDataError({
        ...profieDataError,
        contact_no_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.numberOnlyMsg"
        ),
      });
      return;
    } else if (userProfileData.country_code == "") {
      setProfieDataError({
        ...profieDataError,
        country_code_error: t(
          "MyAccount.MyProfilePage.form.validationMsg.counrtycodeErrMsg"
        ),
      });
      return;
    }
    if (
      `${selectCurrentUser.country_code}${selectCurrentUser.contact_no}` !==
      `${userProfileData.country_code}${userProfileData.contact_no}`
    ) {
      setShowPhoneVerificationModal(true);
    } else {
      submitProfileHandler();
    }
  };

  const handleImageChange = () => {
    const imageSize = inputRef?.current?.files[0].size / 1000000;

    const re = /(\.jpg|\.jpeg|\.png)$/i;

    if (imageSize >= 20) {
      setImageUploadError("Image size should be less than 20 MB");
      setSubmitDisabled(true);
      return;
    } else if (!re.exec(inputRef?.current?.files[0].name)) {
      setImageUploadError("Only Png, Jpg and Jpeg Allowed");
      setSubmitDisabled(true);
      return;
    } else {
      setImageUploadError("");
      setSubmitDisabled(false);
    }
    setUserProfileData({
      ...userProfileData,
      profile_image: inputRef.current.files[0],
    });
    var reader = new FileReader();
    reader.onloadend = function () {
      setImageFile(reader.result);
    };
    reader.readAsDataURL(inputRef.current.files[0]);

    //
  };

  // After Profile Image Upload User Data Update
  useEffect(() => {
    if (imageUploadMsg != null) {
      if (imageUploadMsg.success) {
        let datachecking = userProfileData;
        delete datachecking.profile_image;
        ProfileDataUpdateRequest(datachecking);
        ProfileImageUpdateMessageClear();
      }
    }
  }, [imageUploadMsg]);

  // After Profile Image Upload User Data Update
  useEffect(() => {
    if (selectUserProfileMessage != null) {
      const data = {
        language: languageToShow,
      };
      setButtonSubmit(false);
      UserProfileDetailsRequest(data);
      setShowPhoneVerificationModal(false);
      stateClearAfterTask();
    }
  }, [selectUserProfileMessage]);

  const handlepasswordhideshow = (number) => {
    if (number == 2) {
      setEyes({
        ...eyes,
        oldpassword: !eyes.oldpassword,
      });
    } else if (number == 3) {
      setEyes({
        ...eyes,
        confirmpassword: !eyes.confirmpassword,
      });
    } else {
      setEyes({
        ...eyes,
        password: !eyes.password,
      });
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const insureVerification = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/get`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              mobile: true,
              "Accept-Language": `${store.getState().language.language}`,
              Authorization:
                store.getState().user.currentUser != null
                  ? "Bearer " + store.getState().user.currentUser.token + ""
                  : "",
            },
          }
        );
        setIsEmailVerified(data?.data?.is_email_verified);
        updateUser(data.data); // Update user data
      } catch (e) {
        console.log(e);
        setIsEmailVerified(false);
      }
    };
    insureVerification();
  }, []);

  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Manage Your Account - Wfrlee User Portal</title>
        <meta
          name="description"
          content="Access and manage your Wfrlee account settings, personal details, and preferences all in one place. Secure and user-friendly."
        />
      </Helmet>

      {/* //////////Myprofile page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="col-md-12">
            <div className="accounttotalsection">
              <div className="leftmenu">
                <div className="account-titlearea">
                  <h2>{t("MyAccount.heading")}</h2>
                  <p>{t("MyAccount.subHeading")}</p>
                </div>
                <MyAccountLeftMenu />
              </div>
              <div className="rightsection">
                <h1 style={show ? { display: "none" } : { display: "block" }}>
                  {t("MyAccount.MyProfilePage.heading")}
                </h1>
                <div className="customform">
                  <Form
                    style={show ? { display: "none" } : { display: "block" }}
                    onSubmit={handleProfileSubmit}
                  >
                    <div className="form50">
                      <Form.Group controlId="formBasicFirstname">
                        <Form.Label>
                          {t("MyAccount.MyProfilePage.form.FirstName")} *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={userProfileData.first_name}
                          onChange={handleChaneProfiledata}
                          onKeyUp={handleKeyUp}
                          name="first_name"
                          placeholder={t(
                            "MyAccount.MyProfilePage.form.FirstName"
                          )}
                        />
                        <Form.Text className="text-muted errorformmessage">
                          {profieDataError.first_name_error}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group
                        className="lastname"
                        controlId="formBasicLastname"
                      >
                        <Form.Label>
                          {t("MyAccount.MyProfilePage.form.LastName")} *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={userProfileData.last_name}
                          onChange={handleChaneProfiledata}
                          onKeyUp={handleKeyUp}
                          name="last_name"
                          placeholder={t(
                            "MyAccount.MyProfilePage.form.LastName"
                          )}
                        />
                        <Form.Text className="text-muted errorformmessage">
                          {profieDataError.last_name_error}
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <div className="form50 countryCodePhoneDiv">
                      <Form.Group controlId="formBasicCountryCode">
                        <Form.Label>
                          {t("MyAccount.MyProfilePage.form.CountryCode")} *
                        </Form.Label>

                        <Form.Group controlId="formCheckoutCountryCode">
                          <PhoneInput
                            // defaultCountry="IN"
                            autoFormat={false}
                            enableSearch={true}
                            excludeCountries={[
                              "cu",
                              "ru",
                              "kp",
                              "ua",
                              "ir",
                              "sd",
                              "ss",
                              "sy",
                            ]}
                            // country={"in"}
                            value={userProfileData.country_code}
                            onChange={handleCountryCodeChange}
                            name="countryCode"
                            placeholder={"+91"}
                            className="checkoutcountryCodeInput countryCodeInput"
                          />
                        </Form.Group>
                        <Form.Text className="text-muted errorformmessage">
                          {profieDataError.country_code_error}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formBasicPhone">
                        <Form.Label>
                          {t("MyAccount.MyProfilePage.form.MobileNumberText")} *
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          className="form-control"
                          value={userProfileData.contact_no}
                          onChange={handleChaneProfiledata}
                          placeholder={t(
                            "MyAccount.MyProfilePage.form.MobileNumber"
                          )}
                          name="contact_no"
                          maxLength={10}
                        />
                        <Form.Text className="text-muted errorformmessage">
                          {profieDataError.contact_no_error}
                        </Form.Text>
                      </Form.Group>
                    </div>

                    <Form.Group controlId="formBasicProfileImage">
                      <div className="profilepic">
                        <Form.Label>
                          {t("MyAccount.MyProfilePage.form.ProfileImage")}
                        </Form.Label>

                        <img
                          src={
                            imageFile != "" && imageFile != undefined
                              ? imageFile
                              : selectCurrentUser.profile_image != "" &&
                                selectCurrentUser.profile_image != undefined
                              ? selectCurrentUser.profile_image
                              : DefaultImage
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                        />
                        <span className="profile-image-validation">
                          File size upto 20MB and type jpg, jpeg and png are
                          accepted.
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control"
                          ref={inputRef}
                          onChange={handleImageChange}
                          placeholder="Please Select Image"
                          name="profile-image"
                        />

                        <Form.Text className="text-muted errorformmessage">
                          {ImageUploadError}
                        </Form.Text>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        {t("MyAccount.MyProfilePage.form.Emailid")}
                      </Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        className="form-control"
                        value={userProfileData.email}
                        onChange={handleChaneProfiledata}
                        placeholder={t("MyAccount.MyProfilePage.form.Emailid")}
                        disabled={isEmailVerified}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="formsubmit"
                      disabled={buttonSubmit || submitDisabled}
                    >
                      {buttonSubmit ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        t("MyAccount.MyProfilePage.form.Save")
                      )}
                    </Button>
                  </Form>

                  <p
                    className="text-center pt-30"
                    style={
                      show
                        ? { display: "none", cursor: "pointer" }
                        : { display: "block", cursor: "pointer" }
                    }
                    onClick={handleShowChanePasswrd}
                  >
                    {t(
                      "MyAccount.MyProfilePage.ChangePasswordPage.ChangeYourPassword"
                    )}
                  </p>

                  <div
                    style={show ? { display: "block" } : { display: "none" }}
                  >
                    <h1>
                      {t(
                        "MyAccount.MyProfilePage.ChangePasswordPage.form.heading"
                      )}
                    </h1>

                    <Form onSubmit={handleResetPasswordSubmit}>
                      <Form.Group controlId="formBasicOldPassword">
                        <Form.Label>
                          {t(
                            "MyAccount.MyProfilePage.ChangePasswordPage.form.OldPassword"
                          )}{" "}
                          *
                        </Form.Label>
                        <span className="passwordsec">
                          <Form.Control
                            type={eyes.oldpassword ? "text" : "password"}
                            placeholder={t(
                              "MyAccount.MyProfilePage.ChangePasswordPage.form.OldPassword"
                            )}
                            onChange={handleChange}
                            name="oldpassword"
                            value={userData.oldpassword}
                          />
                          {eyes.oldpassword ? (
                            <AiOutlineEye
                              onClick={() => {
                                handlepasswordhideshow(2);
                              }}
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              onClick={() => {
                                handlepasswordhideshow(2);
                              }}
                            />
                          )}
                          <Form.Text className="text-muted errorformmessage">
                            {userDataError.oldpasswordErr}
                          </Form.Text>
                        </span>
                      </Form.Group>

                      {/* </div> */}
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>
                          {t(
                            "MyAccount.MyProfilePage.ChangePasswordPage.form.Password"
                          )}{" "}
                          *
                        </Form.Label>
                        <span className="passwordsec">
                          <Form.Control
                            type={eyes.password ? "text" : "password"}
                            placeholder={t(
                              "MyAccount.MyProfilePage.ChangePasswordPage.form.Password"
                            )}
                            onChange={handleChange}
                            name="password"
                            value={userData.password}
                          />
                          {eyes.password ? (
                            <AiOutlineEye
                              onClick={() => {
                                handlepasswordhideshow();
                              }}
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              onClick={() => {
                                handlepasswordhideshow();
                              }}
                            />
                          )}
                          <Form.Text className="text-muted errorformmessage">
                            {userDataError.passwordErr}
                          </Form.Text>
                        </span>
                      </Form.Group>
                      <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Label>
                          {t(
                            "MyAccount.MyProfilePage.ChangePasswordPage.form.ConfirmPassword"
                          )}{" "}
                          *
                        </Form.Label>
                        <span className="passwordsec">
                          <Form.Control
                            type={eyes.confirmpassword ? "text" : "password"}
                            placeholder={t(
                              "MyAccount.MyProfilePage.ChangePasswordPage.form.ConfirmPassword"
                            )}
                            onChange={handleChange}
                            name="confirmPassword"
                            value={userData.confirmPassword}
                          />
                          {eyes.confirmpassword ? (
                            <AiOutlineEye
                              onClick={() => {
                                handlepasswordhideshow(3);
                              }}
                            />
                          ) : (
                            <AiOutlineEyeInvisible
                              onClick={() => {
                                handlepasswordhideshow(3);
                              }}
                            />
                          )}
                          <Form.Text className="text-muted errorformmessage">
                            {userDataError.confirmPasswordErr}
                          </Form.Text>
                        </span>
                      </Form.Group>

                      <Button
                        variant="primary"
                        type="submit"
                        className="formsubmit"
                      >
                        {/* Change Password */}
                        {t(
                          "MyAccount.MyProfilePage.ChangePasswordPage.form.heading"
                        )}
                      </Button>
                    </Form>
                  </div>
                  <button
                    className="formback"
                    style={show ? { display: "block" } : { display: "none" }}
                    onClick={handleShowChanePasswrd}
                  >
                    {" "}
                    {t(
                      "MyAccount.MyProfilePage.ChangePasswordPage.form.Back"
                    )}{" "}
                  </button>

                  <p
                    className="text-center pt-30"
                    style={{
                      display: "block",
                      cursor: "pointer",
                      color: "red",
                      fontSize: "smaller",
                    }}
                    onClick={() => {
                      setDialogOpen(true);
                    }}
                  >
                    {t(
                      "MyAccount.MyProfilePage.ChangePasswordPage.DeleteAccount"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth={fullScreen}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your account will be deleted within 14 days ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={"gen-btn-conformation-modal"}
            style={{ width: "142px" }}
            onClick={handleDialogClose}
          >
            No
          </Button>
          <Button
            className={"gen-btn-conformation-modal"}
            style={{ width: "142px" }}
            onClick={handleDeleteAccount}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PhoneVerificationModal
        isVisible={showPhoneVerificationModal}
        defaultCountryCode={selectCurrentUser.country_code}
        defaultPhoneNumber={selectCurrentUser.contact_no}
        onOTPConfirm={() => {
          submitProfileHandler();
        }}
        selectCurrentUser={selectCurrentUser}
      />

      {/* //////////Myprofile page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectCurrentUser: selectCurrentUser,
  selectUserProfileMessage: selectUserProfileMessage,
  resetPasswordData: selectUserResetPassword,
  imageUploadMsg: selectImageUploadMessage,
});
const mapDispatchToProps = (dispatch) => ({
  selectLanguage: (data) => dispatch(selectLanguage(data)),
  ResetPasswordStart: (data) => dispatch(ResetPasswordStart(data)),
  ProfileDataUpdateRequest: (data) => dispatch(ProfileDataUpdateRequest(data)),
  ProfileImageUpdateRequest: (data) =>
    dispatch(ProfileImageUpdateRequest(data)),
  UserProfileDetailsRequest: (data) =>
    dispatch(UserProfileDetailsRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  ProfileImageUpdateMessageClear: () =>
    dispatch(ProfileImageUpdateMessageClear()),
  UserLogoutStart: () => dispatch(UserLogoutStart()),
  updateUser: (data) => dispatch(UserProfileDetailsUpdateThroughProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyprofilePage);
