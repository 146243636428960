import { useEffect, useState } from "react";

export const useCountdown = (initialCount) => {
  const [countdown, setCountdown] = useState(0);

  const startCountdown = (duration = initialCount) => {
    setCountdown(duration);
  };

  useEffect(() => {
    if (countdown > 0) {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  return { countdown, startCountdown };
};
