import { useState } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Form as BootstrapForm,
  Button,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import store from "../../../../redux/store";
import {
  ProfileDataUpdateFailure,
  ProfileDataUpdateRequest,
  requestSocialLogin,
  UserProfileDetailsUpdateThroughProfile,
} from "../../../../redux/user/user.actions";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { useTranslation } from "react-i18next";
import PhoneVerificationModal from "./PhoneVerification/PhoneVerificationModal";

const MySwal = withReactContent(Swal);

const ReconfirmUserData = ({
  selectCurrentUser,
  updateUser,
  requestSocialLogin,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmingPhoneNumber, setIsConfirmingPhoneNumber] = useState(
    Boolean(queryParams.get("otp_visible"))
  );
  const [heldData, setHeldData] = useState({});
  const [justConfirmingPhoneNumber, setJustConfirmingPhoneNumber] = useState(
    Boolean(queryParams.get("otp_visible"))
  );

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, t("loginAndRegisterPage.validationMsg.Minimum3Characters"))
      .required(
        t("loginAndRegisterPage.validationMsg.PleaseEnterYourFirstName")
      )
      .matches(/^[a-zA-Z\s\-]*$/, "English only, Please"),
    lastname: Yup.string()
      .min(3, t("loginAndRegisterPage.validationMsg.Minimum3Characters"))
      .required(t("loginAndRegisterPage.validationMsg.PleaseEnterYourLastName"))
      .matches(/^[a-zA-Z\s\-]*$/, "English only, Please"),
    phoneno: Yup.string()
      .required(t("loginAndRegisterPage.validationMsg.PleaseEnteronlyNumber"))
      .min(10, t("loginAndRegisterPage.validationMsg.digitnumberallowonly10")),
    email: Yup.string()
      .email(
        t("loginAndRegisterPage.validationMsg.PleaseEnteraValidEmailAddress")
      )
      .required(
        t("loginAndRegisterPage.validationMsg.PleaseEnteraValidEmailAddress")
      ),
    termsandConditions: Yup.boolean().oneOf(
      [true],
      t("loginAndRegisterPage.validationMsg.PleaseAcceptTermsAndConditions")
    ),
  });

  const onConfirmOTPHandler = async () => {
    const values = heldData;
    setIsConfirmingPhoneNumber(false);
    try {
      if (state?.new_user) {
        const postData = {
          first_name: values.firstname,
          last_name: values.lastname,
          country_code: values.country_code,
          contact_no: values.phoneno,
          email: values.email,
          social_platform: "google",
          social_login_id: state?.id,
          social_login_token: state?.users.access_token,
        };
        return await requestSocialLogin(postData);
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/get`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mobile: true,
            "Accept-Language": `${store.getState().language.language}`,
            Authorization:
              store.getState().user.currentUser != null
                ? "Bearer " + store.getState().user.currentUser.token
                : "",
          },
        }
      );
      dispatch(updateUser(data.data)); // Update user data
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#5287b3",
        text: t("Profile updated successfully"),
      }).then(function (isConfirm) {
        if (isConfirm) {
          window.location.href = "/";
        }
      });
    } catch (error) {
      dispatch(ProfileDataUpdateFailure(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    setIsConfirmingPhoneNumber(true);
    setHeldData(values);
  };

  const formik = useFormik({
    initialValues: {
      firstname: state?.given_name || state?.first_name || "",
      lastname: state?.family_name || state?.last_name || "",
      phoneno: state?.contact_no || "",
      email: state?.email || "",
      termsandConditions: justConfirmingPhoneNumber,
      country_code: state?.country_code || "+966",
    },
    validationSchema,
    onSubmit: handleSubmit,
    validateOnMount: true,
  });

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="logtotal customform reconfirm_user_data_container">
              <h3 style={{ marginBottom: "1rem" }}>
                {t("loginAndRegisterPage.reconfirmUserData")}
              </h3>
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="form50">
                  <BootstrapForm.Group controlId="formBasicFirstname">
                    <BootstrapForm.Label>
                      {t("loginAndRegisterPage.FirstName")} *
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      placeholder={t("loginAndRegisterPage.FirstName")}
                      {...formik.getFieldProps("firstname")}
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                      <BootstrapForm.Text className="text-muted errorformmessage">
                        {formik.errors.firstname}
                      </BootstrapForm.Text>
                    )}
                  </BootstrapForm.Group>

                  <BootstrapForm.Group controlId="formBasicLastname">
                    <BootstrapForm.Label>
                      {t("loginAndRegisterPage.LastName")} *
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="text"
                      placeholder={t("loginAndRegisterPage.FirstName")}
                      {...formik.getFieldProps("lastname")}
                    />
                    {formik.touched.lastname && formik.errors.lastname && (
                      <BootstrapForm.Text className="text-muted errorformmessage">
                        {formik.errors.lastname}
                      </BootstrapForm.Text>
                    )}
                  </BootstrapForm.Group>
                </div>

                <div className="form50 countryCodePhoneDiv">
                  <BootstrapForm.Group
                    controlId="formBasicPhone"
                    style={{ width: "max-content" }}
                  >
                    <BootstrapForm.Label>
                      {t("loginAndRegisterPage.CountryCode")} *
                    </BootstrapForm.Label>
                    <PhoneInput
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={(value) =>
                        formik.setFieldValue("country_code", value)
                      }
                      inputProps={{
                        name: "country_code",
                        readOnly: true,
                      }}
                      placeholder={"+91"}
                      enableSearch={true}
                      excludeCountries={[
                        "cu",
                        "ru",
                        "kp",
                        "ua",
                        "ir",
                        "sd",
                        "ss",
                        "sy",
                      ]}
                      className="countryCodeInput reconfirm_country_code"
                    />
                    {formik.touched.country_code &&
                      formik.errors.country_code && (
                        <div className="text-muted errorformmessage">
                          {formik.errors.country_code}
                        </div>
                      )}
                  </BootstrapForm.Group>

                  <BootstrapForm.Group controlId="formBasicPhone">
                    <BootstrapForm.Label>
                      {t("loginAndRegisterPage.phoneNumberText")} *
                    </BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="tel"
                      placeholder={t("loginAndRegisterPage.PhoneNumber")}
                      maxLength={10}
                      minLength={10}
                      onKeyDown={(e) => {
                        // Allow only numbers, backspace, delete, and arrow keys
                        const allowedKeys = [
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                          "Tab",
                          "Home",
                          "End",
                        ];
                        if (!allowedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      {...formik.getFieldProps("phoneno")}
                    />
                    {formik.touched.phoneno && formik.errors.phoneno && (
                      <div className="text-muted errorformmessage">
                        {formik.errors.phoneno}
                      </div>
                    )}
                  </BootstrapForm.Group>
                </div>

                <BootstrapForm.Group controlId="formBasicEmailForRegister">
                  <BootstrapForm.Label>
                    {t("loginAndRegisterPage.Email")} *
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="email"
                    placeholder={t("loginAndRegisterPage.Emailaddress")}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <BootstrapForm.Text className="text-muted errorformmessage">
                      {formik.errors.email}
                    </BootstrapForm.Text>
                  )}
                </BootstrapForm.Group>

                <BootstrapForm.Group
                  className="form-checkbox"
                  controlId="formBasicCheckbox"
                  style={{ paddingTop: "13px" }}
                >
                  <BootstrapForm.Check
                    name="termsandConditions"
                    type="checkbox"
                    checked={formik.values.termsandConditions}
                    onChange={formik.handleChange}
                    label=""
                  />
                  <div
                    className="TC-message-container"
                    onClick={() =>
                      formik.setFieldValue(
                        "termsandConditions",
                        !formik.values.termsandConditions
                      )
                    }
                  >
                    {t("loginAndRegisterPage.Iagreeallthe")}{" "}
                    <Link to="/terms&conditions" target="_blank">
                      {t("loginAndRegisterPage.termsandconditions")}
                    </Link>{" "}
                    <span className="px-1">{t("loginAndRegisterPage.&")}</span>{" "}
                    <Link to="/privacy-policy" target="_blank">
                      {t("loginAndRegisterPage.privacypolicy")}
                    </Link>
                  </div>
                  {formik.touched.termsandConditions &&
                    formik.errors.termsandConditions && (
                      <BootstrapForm.Text className="text-muted errorformmessage">
                        {formik.errors.termsandConditions}
                      </BootstrapForm.Text>
                    )}
                </BootstrapForm.Group>

                <div className="d-flex gap-3 w-100">
                  <Button
                    variant="primary"
                    type="submit"
                    className="formsubmit outline-primary"
                    disabled={
                      isLoading || formik.isSubmitting || !formik.isValid
                    }
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      t("Continue and Save")
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    as={Link}
                    className="formsubmit outline-primary"
                    disabled={isLoading || formik.isSubmitting}
                    to="/"
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <PhoneVerificationModal
        defaultCountryCode={formik.values.country_code}
        defaultPhoneNumber={formik.values.phoneno}
        isVisible={isConfirmingPhoneNumber}
        onOTPConfirm={onConfirmOTPHandler}
        selectCurrentUser={selectCurrentUser}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectCurrentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  ProfileDataUpdateRequest: (data) => dispatch(ProfileDataUpdateRequest(data)),
  updateUser: (data) => dispatch(UserProfileDetailsUpdateThroughProfile(data)),
  requestSocialLogin: (data) => dispatch(requestSocialLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconfirmUserData);
