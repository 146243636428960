import React from "react";
import { Button } from "@mui/material";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

export const ModalFooter = ({
  continueButtonDisabled,
  codeRequested,
  continueButtonOnClick,
  onRequestCode,
  countdown,
}) => {
  const [t] = useTranslation("common");

  return (
    <div className={styles.footer}>
      <Button
        disabled={continueButtonDisabled}
        type="submit"
        className={styles.continueButton}
        onClick={continueButtonOnClick}
      >
        {t("Continue and Save")}
      </Button>
      {codeRequested ? (
        <p className={styles.resendCode}>
          {t("didntRecivedCode")}{" "}
          <button
            type={"button"}
            className={styles.resendCodeLink}
            onClick={onRequestCode}
            disabled={countdown > 0}
          >
            {t("Resend")}
          </button>
        </p>
      ) : null}
    </div>
  );
};
