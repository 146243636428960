import { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BACKEND_URL_ENDPOINT, INITIAL_VALUES } from "./constants";
import { useCountdown } from "../../../../../hooks/useCountdown";
import { createValidationSchema } from "./validationSchema";

const MySwal = withReactContent(Swal);

export const usePhoneVerification = (
  defaultPhoneNumber,
  defaultCountryCode,
  t,
  onOTPConfirm,
  id
) => {
  const [codeRequested, setCodeRequested] = useState(false);
  const [requestingOTPCode, setRequestingOTPCode] = useState("idle");
  const [loading, setLoading] = useState(false);
  const { countdown, startCountdown } = useCountdown(2 * 60);
  const validationSchema = createValidationSchema(t);

  const formik = useFormik({
    initialValues: {
      ...INITIAL_VALUES,
      phoneno: defaultPhoneNumber,
      country_code: defaultCountryCode,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BACKEND_URL_ENDPOINT}/user/verify-code`,
          {
            phoneNumber: `+${values.country_code}${values.phoneno}`,
            code: values.otpcode,
            id,
          }
        );
        if (response?.data?.status === false) {
          throw new Error("OTP code is incorrect");
        }
        onOTPConfirm()?.finally(() => {
          setLoading(false);
        });
      } catch (error) {
        MySwal.fire({
          type: "error",
          title: t("An error occurred in verifying the mobile number"),
        });
        setLoading(false);
      }
    },
  });

  const requestOTPRequest = async () => {
    const { phoneno, country_code } = formik.values;
    setRequestingOTPCode("pending");
    try {
      await axios.post(`${BACKEND_URL_ENDPOINT}/user/send-validation-sms`, {
        phoneNumber: `+${country_code}${phoneno}`,
      });
      setRequestingOTPCode("success");
      setCodeRequested(true);
      startCountdown();
    } catch (err) {
      setRequestingOTPCode("rejected");
      MySwal.fire({
        type: "error",
        title: t("An error occurred in requesting OTP code"),
      });
    }
  };

  return {
    formik,
    codeRequested,
    countdown,
    requestingOTPCode,
    requestOTPRequest,
    loading,
  };
};
