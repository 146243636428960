import React from "react";
import { FormikProvider } from "formik";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePhoneVerification } from "./usePhoneVerification";
import { Modal } from "../../../../Modal";
import { ModalHeader } from "./ModalHeader";
import { PhoneNumberInput } from "./PhoneNumberInput";
import { OTPInputSection } from "./OTPInputSection";
import { ModalFooter } from "./ModalFooter";

export const PhoneVerificationModal = ({
  defaultPhoneNumber,
  defaultCountryCode,
  isVisible = false,
  onOTPConfirm = () => {},
  selectCurrentUser,
}) => {
  const [t] = useTranslation("common");
  const {
    formik,
    codeRequested,
    countdown,
    requestingOTPCode,
    requestOTPRequest,
    loading,
  } = usePhoneVerification(
    defaultPhoneNumber,
    defaultCountryCode,
    t,
    onOTPConfirm,
    selectCurrentUser._id
  );

  return (
    <Modal
      modalVisible={isVisible}
      handleClose={() => {}}
      hideCloseButton
      className={{ content: styles.content }}
    >
      <ModalHeader />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.formContainer}>
          <PhoneNumberInput />
          <OTPInputSection
            requestOTPButtonDisabled={
              formik.errors.phoneno ||
              countdown > 0 ||
              requestingOTPCode === "pending"
            }
            onRequestCode={requestOTPRequest}
            codeRequested={codeRequested}
            countdown={countdown}
          />
          <ModalFooter
            codeRequested={codeRequested}
            countdown={countdown}
            continueButtonDisabled={
              formik.errors.phoneno ||
              formik.errors.otpcode ||
              !codeRequested ||
              loading
            }
            continueButtonOnClick={formik.handleSubmit}
            onRequestCode={requestOTPRequest}
          />
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default PhoneVerificationModal;
