import { GrClose } from "react-icons/gr";
import styles from "./Modal.module.css";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

const { container, content, header, body } = styles;

export const Modal = ({
  modalVisible,
  handleClose,
  children,
  hideCloseButton = false,
  className = {},
}) => {
  const onBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    if (modalVisible) {
      window.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          handleClose();
        }
      });
      window.addEventListener("popstate", (event) => {
        handleClose();
      });
      window.document.documentElement.style.overflow = "hidden";
    } else {
      window.document.documentElement.style.overflow = "auto";
    }
  }, [modalVisible]);

  if (modalVisible)
    return (
      <div className={container} onClick={onBackdropClick}>
        <div className={`${content} ${className?.content}`}>
          {hideCloseButton ? null : (
            <div className={header}>
              <Button variant="" onClick={handleClose}>
                <GrClose />
              </Button>
            </div>
          )}
          <div className={body}>{children}</div>
        </div>
      </div>
    );
};
